import { InteractionStatisticsTimeSeriesBucket } from '@capturi/api-insights'
import {
  DateBucket,
  LineDescriptor,
  adjustDateForDSTOverlap,
  getDateExtremes,
} from '@capturi/charts'
import { TimeResolution } from '@capturi/core'
import { Segment } from '@capturi/filters'
import { isWeekend } from 'date-fns'
import { useMemo } from 'react'

export interface TimeSeriesData {
  series: DateBucket[]
  segmentLines: LineDescriptor[]
  minDate: Date
  maxDate: Date
}

type UseTimeSeries = (
  segments: Segment<{ series: InteractionStatisticsTimeSeriesBucket[] }>[],
  valueProperty: keyof InteractionStatisticsTimeSeriesBucket,
  resolution: TimeResolution,
  plotOnlyWeekdays: boolean,
  timezoneOffset: number,
) => TimeSeriesData

export const useTimeSeries: UseTimeSeries = (
  segments,
  valueProperty,
  resolution,
  plotOnlyWeekdays,
  timezoneOffset,
) => {
  return useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = {
          date,
        }
      }
      return buckets[bucketKey]
    }

    function addSegmentDataToBucket(
      segments: Segment<{
        series: InteractionStatisticsTimeSeriesBucket[]
      }>[],
    ): void {
      segments.forEach((s) => {
        ;(s.data?.series ?? []).forEach((x) => {
          const date = adjustDateForDSTOverlap(x.dateTime, timezoneOffset)

          if (
            plotOnlyWeekdays &&
            isWeekend(date) &&
            (resolution === 'Hours' || resolution === 'Days')
          ) {
            return
          }

          const segmentKey = s.label
          const segmentColor = s.color
          const { dateTime, speakTimeAveragePercent, ...restData } = x
          const bucket = getOrCreateBucket(date)
          const datum = {
            speakTimeAveragePercent: speakTimeAveragePercent / 100,
            segmentColor,
            ...restData,
          }

          bucket[segmentKey] = datum
        })
      })
    }

    // Add primary segment data
    addSegmentDataToBucket(segments)

    const segmentLines = segments.reduce<LineDescriptor[]>((memo, s) => {
      // Only add lines when we also have data. Charts will do weird aninmations otherwise.
      if (s.data == null) return memo
      memo.push({
        id: `primary:${s.label}:${s.color}`,
        color: s.color,
        label: s.label,
        getValue: (d) => {
          return d[s.label]?.[valueProperty]
        },
      })
      return memo
    }, [])

    const series = Object.values(buckets)
    const [minDate, maxDate] = getDateExtremes(series)
    return {
      series,
      segmentLines,
      minDate,
      maxDate,
    }
  }, [segments, valueProperty, resolution, plotOnlyWeekdays, timezoneOffset])
}

import {
  LongestMonologueByTeamResponseModel,
  LongestMonologueResponseModel,
} from '@capturi/api-insights'
import { useTeams } from '@capturi/core'
import { Segment } from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { TeamSegments, UserSegments } from '../../shared/types'

type Datum = {
  value: number
  conversations: number
  conversationsTotal: number
}

export type UserSegmentsData = UserSegments<Datum>
export type TeamSegmentsData = TeamSegments<Datum>

export function useLongestMonologueDataStats(
  segments: Segment<LongestMonologueResponseModel>[],
): UserSegmentsData[] {
  const { getUserByUid } = useUsers()

  return useMemo(() => {
    const allUserUids = new Set(
      segments.flatMap((s) => (s.data?.results ?? []).map((x) => x.userUid)),
    )

    const segmentUserData = segments.map<{ [key: string]: Datum }>((s) => {
      return (s.data?.results ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        if (x.salesPerson == null) {
          return acc
        }
        acc[x.userUid] = {
          value: x.salesPerson?.averageLongestMonologue ?? 0,
          conversations: x.conversations,
          conversationsTotal: x.conversationsTotal,
        }
        return acc
      }, {})
    })

    const segmentData = [...allUserUids].reduce<{
      [key: string]: UserSegmentsData
    }>((acc, userUid) => {
      acc[userUid] = {
        segments: segments.map<Segment<Datum>>((s, i) => {
          const map = segmentUserData[i]
          return {
            color: s.color,
            label: s.label,
            data: map[userUid] ?? {
              value: 0,
              conversations: 0,
              conversationsTotal: 0,
            },
          }
        }),
        user: getUserByUid(userUid),
      }
      return acc
    }, {})

    return orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.value, (x) => x.user.name],
      ['desc', 'asc'],
    )
  }, [segments, getUserByUid])
}

export function useLongestMonologueTeamDataStats(
  segments: Segment<LongestMonologueByTeamResponseModel>[],
): TeamSegmentsData[] {
  const { getTeamByUid } = useTeams()

  return useMemo(() => {
    const allTeamUids = new Set(
      segments.flatMap((s) => (s.data?.teams ?? []).map((x) => x.teamUid)),
    )

    const segmentTeamData = segments.map<{ [key: string]: Datum }>((s) => {
      return (s.data?.teams ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        if (x.salesPerson == null) {
          return acc
        }
        acc[x.teamUid] = {
          value: x.salesPerson?.averageLongestMonologue ?? 0,
          conversations: x.conversations,
          conversationsTotal: x.conversationsTotal,
        }
        return acc
      }, {})
    })

    const segmentData = [...allTeamUids].reduce<{
      [key: string]: TeamSegmentsData
    }>((acc, teamUid) => {
      acc[teamUid] = {
        segments: segments.map<Segment<Datum>>((s, i) => {
          const map = segmentTeamData[i]
          return {
            color: s.color,
            label: s.label,
            data: map[teamUid] ?? {
              value: 0,
              conversations: 0,
              conversationsTotal: 0,
            },
          }
        }),
        team: getTeamByUid(teamUid) ?? {
          uid: teamUid,
          name: 'N/A',
          members: [],
        },
      }
      return acc
    }, {})

    return orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.value, (x) => x.team.name],
      ['desc', 'asc'],
    )
  }, [segments, getTeamByUid])
}

import { ColorLabel } from '@capturi/charts'
import { Segment } from '@capturi/filters'
import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import Tab from '../../shared/components/Tab'

type Props = {
  label: React.ReactNode
  values: Segment<number | string>[]
  isLoading: boolean
  onClick?: () => void
  infoText?: string
}

const InteractionTab: React.FC<Props> = ({
  label,
  values = [],
  isLoading,
  ...restProps
}) => {
  return (
    <Tab.Container {...restProps}>
      <Tab.Title label={label} />
      <Tab.Display>
        {() => {
          return values.length === 1 ? (
            <Tab.Value isLoading={isLoading}>{values[0].data}</Tab.Value>
          ) : (
            <Box
              display="inline-flex"
              flexDirection="column"
              fontSize="0.875rem"
              lineHeight={1.2}
              fontWeight="500"
              color="gray.800"
            >
              {values.map((v) => (
                <Flex
                  display="inline-flex"
                  justify="space-between"
                  align="center"
                  wrap="nowrap"
                  key={v.label}
                >
                  <ColorLabel color={v.color} m={1} mr={2} />
                  <Tab.Value isLoading={isLoading}>{v.data}</Tab.Value>
                </Flex>
              ))}
            </Box>
          )
        }}
      </Tab.Display>
    </Tab.Container>
  )
}

export default InteractionTab

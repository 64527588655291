import {
  InteractionSummaryResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { useCurrentUser } from '@capturi/core'
import {
  toFilterSearchParams,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { DefaultFallbackComponent } from '@capturi/react-utils'
import { useUsers } from '@capturi/stores'
import { TabList, TabPanel, TabPanels, Tabs } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import React from 'react'
import formatSeconds from 'utils/formatSeconds'

import NoAccessibleTrackers from '../shared/components/NoAccessibleTrackers'
import TeamFilterRequiredMessage from '../shared/components/TeamFilterRequiredMessage'
import { Event, logEvent } from '../shared/events'
import { TeamSegments, UserSegments } from '../shared/types'
import { formatValue, pctFormat } from '../shared/utils'
import Tab from './components/InteractionTab'
import { LongestMonologue } from './components/LongestMonologue'
import NoConversations from './components/NoConversations'
import { SpeakerDistribution } from './components/SpeakerDistribution'
import { SpeakingRate } from './components/SpeakingRate'

const DataTabs: React.FC = () => {
  const {
    segments: summarySegments,
    isLoading,
    error: segmentError,
  } = useFetchSegments<InteractionSummaryResponseModel>(
    insightsAPI.getInteractionSummaries,
  )

  const user = useCurrentUser()
  const { getUsersByTeamUid } = useUsers()
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const openConversationsDrawer = useConversationsDrawer()
  const { periodDef } = useFilterPeriodContext()

  const handleOpenConversationDrawerForUserUids = React.useCallback(
    (userUids: string[], segmentIndex: number, dataTabName: string): void => {
      logEvent(Event.DataGrid_ViewUserConversations, { dataTabName })
      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...states[segmentIndex].values,
              userUids: userUids,
            },
            periodDef,
          ),
      })
    },
    [openConversationsDrawer, periodDef, states],
  )

  const handleOpenConversationsDrawer = React.useCallback(
    (
      row: UserSegments | TeamSegments,
      segmentIndex: number,
      dataTabName: string,
    ): void => {
      if ('user' in row) {
        handleOpenConversationDrawerForUserUids(
          [row.user.uid],
          segmentIndex,
          dataTabName,
        )
      } else {
        const users = getUsersByTeamUid(row.team.uid)
        if (users.length > 0) {
          handleOpenConversationDrawerForUserUids(
            users.map((u) => u.uid),
            segmentIndex,
            dataTabName,
          )
        }
      }
    },
    [getUsersByTeamUid, handleOpenConversationDrawerForUserUids],
  )

  if (user.isTeamLead && segmentError && segmentError.statusCode === 403)
    return <TeamFilterRequiredMessage />

  if (segmentError && segmentError.statusCode === 403)
    return <NoAccessibleTrackers />

  if (segmentError) return <DefaultFallbackComponent error={segmentError} />

  const isEmpty = !(
    isLoading ||
    summarySegments.some(
      (segment) => segment.data && segment.data.conversations !== 0,
    )
  )

  const isTeamFilterRequiredMessage =
    !isLoading && user.isTeamLead && !summarySegments[0].data

  return (
    <Tabs my={6} isFitted isLazy>
      <TabList flexWrap="wrap" alignItems="stretch">
        <Tab
          isLoading={isLoading}
          label={t`Talk ratio`}
          values={summarySegments.map((s) => ({
            color: s.color,
            label: s.label,
            data: s.data
              ? formatValue(s.data.speakTimeAveragePercent / 100, pctFormat)
              : '',
          }))}
          onClick={() =>
            logEvent(Event.DataTab_Clicked, { name: 'talk-ratio' })
          }
        />
        <Tab
          isLoading={isLoading}
          label={t`Speech rate`}
          values={summarySegments.map((s) => ({
            color: s.color,
            label: s.label,
            data: s.data
              ? `${formatValue(s.data.speakRateAverage, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} ${t`wpm`}`
              : '',
          }))}
          onClick={() =>
            logEvent(Event.DataTab_Clicked, { name: 'speech-rate' })
          }
        />
        <Tab
          isLoading={isLoading}
          label={t`Longest Monologue`}
          values={summarySegments.map((s) => ({
            color: s.color,
            label: s.label,
            data: formatSeconds(s.data?.longestMonologueAverage),
          }))}
          onClick={() =>
            logEvent(Event.DataTab_Clicked, { name: 'longest-monologue' })
          }
        />
      </TabList>
      {isTeamFilterRequiredMessage && <TeamFilterRequiredMessage />}
      {!isTeamFilterRequiredMessage && isEmpty && <NoConversations />}
      {!(isTeamFilterRequiredMessage || isEmpty) && (
        <TabPanels flex="1">
          <TabPanel>
            <SpeakerDistribution
              summaries={summarySegments}
              onPlaySnippets={(row, segment) =>
                handleOpenConversationsDrawer(row, segment, 'talk-ratio')
              }
            />
          </TabPanel>
          <TabPanel>
            <SpeakingRate
              summaries={summarySegments}
              onPlaySnippets={(row, segment) =>
                handleOpenConversationsDrawer(row, segment, 'speech-rate')
              }
            />
          </TabPanel>
          <TabPanel>
            <LongestMonologue
              summaries={summarySegments}
              onPlaySnippets={(row, segment) =>
                handleOpenConversationsDrawer(row, segment, 'longest-monologue')
              }
            />
          </TabPanel>
        </TabPanels>
      )}
    </Tabs>
  )
}

export default DataTabs

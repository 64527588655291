import {
  InteractionStatisticsTimeSeriesBucket,
  insightsAPI,
} from '@capturi/api-insights'
import {
  CartesianGrid,
  LineChartSkeleton,
  PrimaryLine,
  TimeSeriesChart,
  TimeSeriesTooltip,
  XAxis,
  YAxis,
} from '@capturi/charts'
import { TimeResolution } from '@capturi/core'
import { useFetchSegments, useFilterPeriodContext } from '@capturi/filters'
import { Box, HStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'
import { ResponsiveContainer, Tooltip } from 'recharts'
import formatSeconds from 'utils/formatSeconds'

import ResolutionButtons from '../../../shared/components/ResolutionButtons'
import { useTimeResolutionContext } from '../../../shared/contexts/ResolutionContext'
import useOnlyWeekdaysToggle from '../../../shared/hooks/useOnlyWeekdaysToggle'
import { useTimeSeries } from '../../data/useTimeSeries'

interface TimeSeriesProps {
  onResolutionChange: (resolution: TimeResolution) => void
}

export const TimeSeries: React.FC<TimeSeriesProps> = ({
  onResolutionChange,
}) => {
  const { resolution, setResolution } = useTimeResolutionContext()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1
  const [plotOnlyWeekdays, OnlyWeekdaysToggle] = useOnlyWeekdaysToggle()

  const { segments, isLoading } = useFetchSegments<{
    series: InteractionStatisticsTimeSeriesBucket[]
  }>(() => insightsAPI.getInteractionStatisticsTimeSeries(resolution, tzOffset))

  const { series, segmentLines } = useTimeSeries(
    segments,
    'longestMonologueAverage',
    resolution,
    plotOnlyWeekdays,
    tzOffset,
  )
  return (
    <Box>
      <HStack spacing={4} mb={2}>
        <ResolutionButtons
          resolution={resolution}
          setResolution={(resolution) => {
            setResolution(resolution)
            onResolutionChange(resolution)
          }}
        />
        {OnlyWeekdaysToggle}
      </HStack>
      <Box w="100%" h="14rem" mt={4}>
        <LineChartSkeleton isLoaded={!isLoading}>
          <ResponsiveContainer width="100%" height="100%" minWidth={100}>
            <TimeSeriesChart data={series} resolution={resolution}>
              <CartesianGrid />
              <Tooltip
                content={
                  <TimeSeriesTooltip
                    resolution={resolution}
                    formatValue={(value, name, d) => {
                      const conversations = d[name].conversations
                      const numberValue = formatSeconds(value)
                      const numberConversations = i18n.number(conversations)
                      return t`${numberValue} (${numberConversations} conversations)`
                    }}
                  />
                }
              />
              <XAxis dataKey="date" />
              <YAxis tickFormatter={formatSeconds} />
              {segmentLines.map((line) => (
                <PrimaryLine key={line.id} line={line} />
              ))}
            </TimeSeriesChart>
          </ResponsiveContainer>
        </LineChartSkeleton>
      </Box>
    </Box>
  )
}

import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import { ContentPlaceholder } from '@capturi/ui-components'
import { Trans } from '@lingui/macro'
import React from 'react'

const NoConversations: React.FC = () => {
  return (
    <ContentPlaceholder.Container mt={20}>
      <ContentPlaceholder.Image as={Icon_EmptyState} />
      <ContentPlaceholder.Heading>
        <Trans>No conversations to base the interaction on</Trans>
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        <Trans>
          Try extending the period in the top right corner or make your
          filtration wider
        </Trans>
      </ContentPlaceholder.Body>
    </ContentPlaceholder.Container>
  )
}

export default NoConversations

import { ColumnDefinition, DataGrid } from 'components/DataGrid'
import { DataType } from 'components/DataGrid/types'
import React from 'react'

import NoDataText from '../../shared/components/NoDataText'
import UserBreakdownSkeleton from '../../shared/components/UserBreakdownSkeleton'

type Props<T extends DataType> = {
  data: T[]
  columns: ColumnDefinition<T>[]
  isLoading: boolean
}

export default function Breakdown<T extends DataType>({
  data,
  columns,
  isLoading,
}: Props<T>): React.ReactElement {
  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid data={data} columns={columns}>
        <NoDataText show={!isLoading && data.length === 0} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
}

import analytics from '@capturi/analytics'

export enum Event {
  // Time series chart
  TimeSeries_ChangeResolution = 'analytics_Interaction_TimeSeries_ChangeResolution',
}

export function logEvent(
  event: Event,
  dataTabName: string,
  data: Record<string, string | number> = {},
): void {
  analytics.event(event, {
    dataTabName,
    ...data,
  })
}

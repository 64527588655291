import {
  InteractionSummaryResponseModel,
  SpeakerDistributionByTeamResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { Segment, useFetchSegments } from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { ColumnDefinition } from 'components/DataGrid'
import React from 'react'

import { formatValue, pctFormat } from '../../../shared/utils'
import {
  TeamSegmentsData,
  useSpeakerDistributionTeamDataStats,
} from '../../data/useSpeakerDistributionDataStats'
import Breakdown from '../Breakdown'

type Props = {
  summaries: Segment<InteractionSummaryResponseModel>[]
  onPlaySnippets: (row: TeamSegmentsData, segmentIndex: number) => void
}

export const TeamBreakdown = React.memo<Props>(
  ({ summaries, onPlaySnippets }) => {
    const { segments, isLoading } =
      useFetchSegments<SpeakerDistributionByTeamResponseModel>(
        insightsAPI.getSpeakerDistributionStatsByTeam,
      )

    const stats = useSpeakerDistributionTeamDataStats(segments)

    const columns = React.useMemo(() => {
      const columns: Array<ColumnDefinition<TeamSegmentsData>> = [
        {
          type: 'team',
          getValue: (d) => d.team.uid,
        },
        {
          type: 'value',
          getSegmentValue: (s) => s.data?.value,
          formatValue: (value) => formatValue(value, pctFormat),
          alignRight: true,
        },
        {
          type: 'progress',
          getSegmentValue: (s) => s.data?.value,
          formatValue: (value) =>
            formatValue(value, {
              style: 'percent',
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            }),
          range: [0, 1],
          referenceLines: summaries.reduce<
            { value: number; color: string; label: string }[]
          >((memo, s) => {
            if (s.data != null) {
              memo.push({
                label: t`Avg. ${i18n.number(
                  s.data.speakTimeAveragePercent / 100,
                  pctFormat,
                )}`,
                color: s.color,
                value: s.data.speakTimeAveragePercent / 100,
              })
            }
            return memo
          }, []),
        },
        {
          type: 'dataBasis',
          getSegmentValue: (s) => {
            const { conversations = 0, conversationsTotal = 0 } = s.data ?? {}
            return {
              value: conversations,
              total: conversationsTotal,
            }
          },
        },
        {
          type: 'button',
          render: (row, segmentIndex) => (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() => onPlaySnippets(row, segmentIndex)}
            />
          ),
        },
      ]
      return columns
    }, [summaries, onPlaySnippets])

    return <Breakdown data={stats} columns={columns} isLoading={isLoading} />
  },
)
